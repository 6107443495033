import { PartnerApiError } from './partner-api-error';

export class PartnerApiResponse {

    success: boolean;
    response: any;
    error: any;

    constructor({success, response, error}: { success?: boolean, response?: any, error?: any } = {}) {
        this.success = success;
        this.response = response;
        this.error = error;
    }

    static fromJson(json: any = {}) {
        return new PartnerApiResponse({
            success: json.success,
            response: json.response,
            error: json.error
        });
    }

    static parse(json: any = {}) {
        const data = PartnerApiResponse.fromJson(json);
        if (data.success === true) {
            return data.response;
        }
        if (data.success === false) {
            throw PartnerApiError.fromJSON(data.error);
        }
        return json;
    }

}
