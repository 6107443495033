import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomErrorStateMatcher } from '../../../../../../src/app/core-api/error-state-matcher';
import { AuthService } from '../../../../../../src/app/core-api/auth.service';
import { ActivatedRoute } from '@angular/router';
import { H5WorkflowService } from '../../../../../../src/app/h5-workflow-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../environments/environment';
import { RequestMethod } from '../../../../../../src/app/core-api/auth';
import { delay, finalize, tap } from 'rxjs/operators';

const TOKEN_PARAM_NAME = 'accessToken';

@Component({
  selector: 'app-card-on-file',
  templateUrl: './card-on-file.component.html',
  styleUrls: ['./card-on-file.component.scss']
})
export class CardOnFileComponent implements OnInit {
  public cardOnFileForm = this.fb.group({
    creditCardNumber: ['', [Validators.required]],
    expire: ['', [Validators.required]],
    cvv: ['', [Validators.required, Validators.pattern('\\d{3,4}')]],
    zipCode: ['', [Validators.required]]
  });
  @ViewChild('monerisFrame') monerisFrame: any;
  public matcher = new CustomErrorStateMatcher();
  public loading = false;
  public success = false;
  private token: string;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    @Inject(ActivatedRoute) private route: ActivatedRoute,
    private h5WorkflowService: H5WorkflowService,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
  ) {}

  public get expire() {
    return this.cardOnFileForm.get('expire');
  }

  public get cvv() {
    return this.cardOnFileForm.get('cvv');
  }

  public get zipCode() {
    return this.cardOnFileForm.get('zipCode');
  }

  public get creditCardNumber() {
    return this.cardOnFileForm.get('creditCardNumber');
  }

  ngOnInit(): void {
    this.authService.apiKey = environment.partnerApiKey;
    this.authService.actualTenantId = environment.tenantId;

    this.route.queryParams.subscribe(params => {
      this.token = params[TOKEN_PARAM_NAME];
      this.authService.accessToken = this.token;
    });
  }



  submitCardOnFileForm(form: FormGroup) {
    if (form.invalid) {
      return;
    }
    this.loading = true;
    console.log(form);

    const data = {
      authorization_state: 'Approved',
      balance: 500,
      card_type: 'Visa',
      card_issuer: '',
      first_six: '411111',
      last_four: '1111',
      expire: '1223',
      IsWebRequest: true
    };
    const url = `${environment.partnerApiUrl}/v5/wallet/mock/funding`;

    this.authService.apiRequest(RequestMethod.POST, url, data, this.h5WorkflowService.getHeadersForFunding()).pipe(
      finalize(() => {
        this.loading = false;
      }),
      tap(() => {
        this.openSnackBar('Successful!');
        this.success = true;
      }),
      delay(1500)
    ).subscribe(() => {
      window.location.href = 'http://demo.acndsb.com/api/payment/paymentredirect?status=success';

    }, () => {
      window.location.href = 'http://demo.acndsb.com/api/payment/paymentredirect?status=error';
    });
  }

  private openSnackBar(message: string, action = 'Got it') {
    this.snackBar.open(message, action, {
      duration: 1000 * 5 * 10,
    });
  }

}
