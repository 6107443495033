import { Injectable } from '@angular/core';

@Injectable()
export class H5WorkflowService {
    public isPhoneVerified = false;
    public isSmsVerified = false;

    // headers
    // tslint:disable-next-line: variable-name
    private _deviceId: string;
    // tslint:disable-next-line: variable-name
    private _appVersion: string;
    // tslint:disable-next-line: variable-name
    private _appBundleId: string;
    // tslint:disable-next-line: variable-name
    private _lat: string;
    // tslint:disable-next-line: variable-name
    private _lon: string;
    // tslint:disable-next-line: variable-name
    private _long: string;
    // tslint:disable-next-line: variable-name
    private _os: string;

    set deviceId(value: string) {
        if (value) {
            this._deviceId = value;
        }
    }

    set appVersion(value: string) {
        if (value) {
            this._appVersion = value;
        }
    }

    set appBundleId(value: string) {
        if (value) {
            this._appBundleId = value;
        }
    }

    set lat(value: string) {
        if (value) {
            this._lat = value;
        }
    }

    set lon(value: string) {
        if (value) {
            this._lon = value;
        }
    }

    set long(value: string) {
        if (value) {
            this._long = value;
        }
    }

    set os(value: string) {
        if (value) {
            this._os = value;
        }
    }

    constructor() {
    }

    public getHeadersForFunding() {
        if (typeof this._lon === 'undefined') {
            this._lon = this._long;
        }
        return {
            ['x-p97-deviceid']: this._deviceId,
            ['x-p97-appversionnumber']: this._appVersion,
            ['x-p97-appbundleid']: this._appBundleId,
            ['x-p97-latitude']: typeof this._lat !== 'undefined' ? this._lat : '0',
            ['x-p97-longitude']: typeof this._lon !== 'undefined' ? this._lon : '0',
            ['x-p97-os']: this._os
        };
    }

    handleErrorState({key, errorType, partnerApiMessage}: { key?: string, errorType?: string, partnerApiMessage?: string }) {
        window.location.href = `/error?key=${key ? encodeURIComponent(key) : ''}&errorType=${errorType ? encodeURIComponent(errorType) : ''}&partnerApiMessage=${partnerApiMessage ? encodeURIComponent(partnerApiMessage) : ''}`;
    }

    handleCofSuccessState({message, userPaymentSourceId}: { message?: string, userPaymentSourceId?: number }) {
        window.location.href = `/card-on-file/success?message=${message ? encodeURIComponent(message) : ''}&userPaymentSourceId=${userPaymentSourceId}`;
    }

    handleBimSuccessState({message, userPaymentSourceId}: { message?: string, userPaymentSourceId?: number }) {
        window.location.href = `/bim/authenticate/success?message=${message ? encodeURIComponent(message) : ''}&userPaymentSourceId=${userPaymentSourceId}`;
    }
}
