import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { ThemeModule } from '../../../../src/app/core-api/theme.module';
import { H5WorkflowService } from '../../../../src/app/h5-workflow-service';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CardOnFileComponent } from './components/card-on-file/card-on-file.component';
import { NoopInterceptor } from '../../../../src/app/core-api/interceprtors/noop-interceptor';

@NgModule({
  declarations: [
    AppComponent,
    CardOnFileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    ThemeModule
  ],
  providers: [
    H5WorkflowService,
    { provide: HTTP_INTERCEPTORS, useClass: NoopInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
