import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { RequestMethod } from './auth';
import { PartnerApiResponse } from './partner-api-response';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  get apiKey(): string {
    return this._apiKey;
  }

  set apiKey(value: string) {
    this._apiKey = value;
  }

  get actualTenantId(): string {
    return this._actualTenantId;
  }

  set actualTenantId(value: string) {
    this._actualTenantId = value;
  }

  get accessToken(): string {
    return `${this.getAuthTokenPrefix()} ${this._accessToken}`;
  }

  set accessToken(value: string) {
    this._accessToken = value;
  }

  get redirectUrl(): string {
    return this._redirectUrl;
  }

  set redirectUrl(value: string) {
    this._redirectUrl = value;
  }

  private _accessToken: string;
  private _actualTenantId: string;
  private _apiKey: string;
  private _redirectUrl: string;

  constructor(private http: HttpClient) {
  }

  private getAuthTokenPrefix() {
    return this._accessToken?.length > environment.minimumJwtTokenLength ?
      environment.bearerAuthTokenPrefix : environment.janrainAuthTokenPrefix;
  }

  public apiRequest(method: RequestMethod, url: string, body?: any, headers?: any) {
    return this.http.request(method, url, {
      body,
      headers
    }).pipe(map(PartnerApiResponse.parse));
  }
}
