import { PZRequest } from 'portal-utils';
import { PartnerApiResponse } from './partner-api-response';
import { EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';

export enum RequestMethod {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    DELETE = 'delete'
}

/**
 * use auth service
 * @deprecated
 */
export class Auth {
    static tokenReceivedEmitter = new EventEmitter<string>();
    static pzRequest: PZRequest;
    static token: string;
    static apiKey: string;
    static tenantId: string;

    constructor() {
    }
    static setAuthToken(token: string) {
        this.token = token;
        if (this.pzRequest) {
            this.pzRequest.accessToken = token;
        }
    }

    static setApiKey(apiKey: string) {
        this.apiKey = apiKey;
        if (this.pzRequest) {
            this.pzRequest.apiKey = apiKey;
        }
    }

    static setTenantId(tenantId: string) {
        this.tenantId = tenantId;
        if (this.pzRequest) {
            this.pzRequest.tenantId = tenantId;
        }
    }

    static getAuthToken(): string {
        return this.token;
    }

    static getApiKey(): string {
        return this.apiKey;
    }

    static getTenantId(): string {
        return this.tenantId;
    }

    static getAuthTokenPrefix() {

        return this.token
            && this.token.length > environment.minimumJwtTokenLength ?
                environment.bearerAuthTokenPrefix : environment.janrainAuthTokenPrefix;
    }

    static isLoggedIn(): boolean {
        return !!this.token;
    }

    static request(): PZRequest {
        if (!Auth.pzRequest) {
            Auth.pzRequest = new PZRequest(Auth.getAuthToken());
            Auth.pzRequest.apiKey = Auth.getApiKey();
            Auth.pzRequest.tenantId = Auth.getTenantId();
        }
        Auth.pzRequest.authPrefix = Auth.getAuthTokenPrefix();
        return Auth.pzRequest;
    }

    static apiRequest(method: RequestMethod, url: string, body?: any, headers?: any) {
        return Auth.request()[method](url, body, headers).then(PartnerApiResponse.parse).catch(e => {
            // if unauthorised, call for token
            if (e && (e.key === 401 || e.statusCode === 401)) {
                Auth.callForToken();
                return Promise.race([
                    new Promise(resolve => {
                        setTimeout(resolve, environment.waitForTokenCallback);
                    }).then(() => null),
                    this.tokenReceivedEmitter.pipe(take(1)).toPromise()
                ]).then((token: any) => {
                    // set token and recall request
                    Auth.setAuthToken(token);
                    return Auth.apiRequest(method, url, body);
                });
            }
            throw e;
        });
    }

    static callForToken() {
        window.location.href = environment.tokenCallUrl;
    }
}
