// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultModule: 'card-on-file',
  tokenParamName: 'token',
  googleAnalyticsCode: 'UA-63718809-4',
  partnerApiUrl: 'https://securepartnerapi-lab.petrozone.com',
  phoneVerification: false,
  apiEnv: 'lab',
  janrainAuthTokenPrefix: 'Janrain',
  bearerAuthTokenPrefix: 'Bearer',
  waitForTokenCallback: 10 * 1000,
  tokenCallUrl: 'sma://p97/token',
  minimumJwtTokenLength: 25
};

export const enum Environments {
  lab = 'lab',
  dev = 'dev',
  qa = 'qa',
  stg = 'stg'
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
