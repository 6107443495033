export class PartnerApiError extends Error {

    key: string;
    message: string;
    errorType: string;
    stack: string;

    constructor({key, message, errorType}: {key: string, message: string, errorType: string}) {
        super();
        this.key = key;
        this.message = message;
        this.errorType = errorType;
        if (typeof (Error as any).captureStackTrace === 'function') {
            (Error as any).captureStackTrace(this, this.constructor);
        } else {
            this.stack = (new Error(message)).stack;
        }
    }

    static fromJSON(json) {
        return new PartnerApiError({
            key: json.key,
            message: json.partnerApiMessage,
            errorType: json.errorType,
        });
    }
}
