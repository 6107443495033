import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';

/** Pass untouched request through to the next request handler. */
@Injectable({
  providedIn: 'root'
})
export class NoopInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('i18n')) { return next.handle(req); }

    console.log('authService: ', this.authService);
    const headers = new HttpHeaders({
      Authorization: this.authService.accessToken,
      'x-p97-tenantId': this.authService.actualTenantId,
      'x-p97-apikey': this.authService.apiKey,
      'Cache-Control': 'no-cache, no-store, private, must-revalidate, max-age=0, no-transform',
      Pragma: 'no-cache',
      Expires: '0',
    });
    // Clone the request and set the new header in one step.
    const authReq = req.clone({ headers });
    console.log('authService:', authReq);

    return next.handle(authReq);
  }
}
