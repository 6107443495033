<div class="loading-shade" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="success" fxLayout="column" fxFill fxLayoutAlign="center center">
  <h1>Card added successfully</h1>
</div>
<div *ngIf="!success" class="card-on-file">
  <h1>Add payment method:<br>Credit card</h1>
  <h3>Please enter a valid credit card info</h3>
  <form [formGroup]="cardOnFileForm" name="cardOnFileForm"
        (submit)="submitCardOnFileForm(cardOnFileForm)" novalidate>
    <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="30px">
      <div fxLayout="column" fxLayoutGap="10px" class="full-width">
        <mat-form-field class="full-width" color="accent" appearance="outline">
          <mat-label>Credit Card Number</mat-label>
          <input [errorStateMatcher]="matcher"
                 matInput
                 type="tel"
                 id="creditCardNumber"
                 name="expire"
                 mask="0000 0000 0000 0000"
                 formControlName="creditCardNumber"
                 required>
          <mat-error *ngIf="expire.hasError('required')">
            Credit Card Number is required
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width" color="accent" appearance="outline">
          <mat-label>Expiry date (MM/YY)</mat-label>
          <input [errorStateMatcher]="matcher"
                 matInput
                 type="tel"
                 id="expire"
                 name="expire"
                 formControlName="expire"
                 maxlength="7"
                 mask="00/00"
                 required>

          <mat-error *ngIf="expire.hasError('required')">
            Expiration Date is required
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width" color="accent" appearance="outline">
          <mat-label>Security code (CVV)</mat-label>
          <input [errorStateMatcher]="matcher" matInput id="cvv" name="cvv" formControlName="cvv" maxlength="4" required>
          <mat-error class="mat-small" *ngIf="cvv.hasError('required')">
            Security code (CVV) is required
          </mat-error>
          <mat-error class="mat-small" *ngIf="cvv.hasError('pattern')">
            Security code (CVV) is invalid
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width" color="accent" appearance="outline">
          <mat-label>Postal code</mat-label>
          <input [errorStateMatcher]="matcher"
                 matInput
                 type="tel"
                 id="zipCode"
                 name="zipCode"
                 formControlName="zipCode"
                 maxlength="5"
                 pattern="\d{5}"
                 required>

          <mat-error *ngIf="zipCode.hasError('required')">
            Postal code is required
          </mat-error>
          <mat-error *ngIf="zipCode.hasError('pattern')">
            Wrong Postal code format
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between center" class="full-width">
        <button color="accent" class="full-width" mat-raised-button type="submit" [disabled]="cardOnFileForm.invalid">
          Add Credit Card
        </button>
      </div>

    </div>
  </form>
</div>
